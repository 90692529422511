import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import { StoplightProject } from '@stoplight/elements-dev-portal';

import '../styles/_api_reference.scss';

export default class ApiReference extends React.Component {
  constructor(props) {
    super(props);
    this.defaultAuthToken = 'y_NbAkKc66ryYTWUXYEu';
    this.state = {
      authToken: this.defaultAuthToken,
    };
    this.updateAuthTokenExampleChange =
      this.updateAuthTokenExampleChange.bind(this);
    this.updateAuthTokenTryItKeyUp = this.updateAuthTokenTryItKeyUp.bind(this);
    this.updateAuthTokenClickEvent = this.updateAuthTokenClickEvent.bind(this);
    this.setTryItAuthTokenInput = this.setTryItAuthTokenInput.bind(this);
  }

  updateAuthTokenExampleChange(event) {
    // Called when a change event is sent on the custom input we create
    this.setTryItAuthTokenInput(event.target.value);
    this.setState({ authToken: event.target.value });
  }

  updateAuthTokenTryItKeyUp(event) {
    // Called when a keydown event is sent on the page

    let toSetValue = event.target.value;

    // If the value is already prepended with `Token token=` then strip it.
    if (toSetValue && toSetValue.indexOf('Token token=') == 0) {
      toSetValue = toSetValue.slice(12);
    }
    // If there's a custom value in the input then update the state
    if (toSetValue && event.target.ariaLabel === 'Authorization') {
      this.setState({ authToken: toSetValue });
    }
  }

  updateAuthTokenClickEvent(event) {
    // Called when a click event is sent on the page
    // Get input and return if it's not visible.
    let authInput = document.querySelector(
      '.ParameterGrid input[aria-label="Authorization"]'
    );
    if (!authInput) return;
    this.setTryItAuthTokenInput(this.state.authToken);
  }

  setTryItAuthTokenInput(toSetValue) {
    // Sets the value of the TryIt Auth Token Input
    let tryItAuthInput = document.querySelector(
      '.ParameterGrid input[aria-label="Authorization"]'
    );
    if (!tryItAuthInput) return;

    // If the value is already prepended with `Token token=` then strip it.
    if (toSetValue.indexOf('Token token=') == 0) {
      toSetValue = toSetValue.slice(12);
    }

    // Very ugly hack to update React DOM element
    const valueSetter = Object.getOwnPropertyDescriptor(
      tryItAuthInput,
      'value'
    ).set;
    const prototype = Object.getPrototypeOf(tryItAuthInput);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(
      prototype,
      'value'
    ).set;

    toSetValue = `Token token=${toSetValue}`;
    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(tryItAuthInput, toSetValue);
    } else {
      valueSetter.call(tryItAuthInput, toSetValue);
    }
    tryItAuthInput.dispatchEvent(new Event('input', { bubbles: true }));
  }

  render() {
    return (
      <Layout>
        <SEO title="API Reference"></SEO>
        <div className="sm:flex sm:items-center p-10 sm:px-10 bg-gray-light sm:h-lane">
          <div className="container mx-auto flex flex-col sm:flex-row items-center justify-between">
            <div className="test-api-key py-10">
              Test API Token:
              <input
                id="api-token"
                name="Test API Authorization Token: "
                type="text"
                aria-label="Authorization"
                label="API Token"
                onChange={this.updateAuthTokenExampleChange}
                size="26"
                value={this.state.authToken}
              />
            </div>
          </div>
        </div>
        <div
          onClick={this.updateAuthTokenClickEvent}
          onKeyUp={this.updateAuthTokenTryItKeyUp}
        >
          <StoplightProject
            basePath="api-reference"
            projectId={process.env.GATSBY_STOPLIGHT_API_REFERENCE_PROJECT_ID}
            router={typeof window === 'undefined' ? 'memory' : 'history'}
          />
        </div>
        <footer className="container p-gut h-gut text-gray-dark mb-gut">
          See a typo, mistake, or missing content? You can{' '}
          <a
            href="https://github.com/PagerDuty/api-schema"
            className="text-green hover:bg-yellow"
          >
            contribute to this documention on Github.
          </a>
        </footer>
      </Layout>
    );
  }
}
